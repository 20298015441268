import React, { useState, useEffect } from 'react';
type User = {
  _id: string;
  name: string;
  email: string;
  number: string;
  refree: string;
  createdAt: string;
  active: boolean; // Added active status
};

const AdminDashboard = () => {
    const [userCount, setUserCount] = useState(0);
    const [totalDeposits, setTotalDeposits] = useState(0);
    const [totalWithdrawals, setTotalWithdrawals] = useState(0);
    const [totalTransfers, setTotalTransfers] = useState(0);
    const [users, setUsers] = useState<User[]>([]);
    const [totalReferralIncome, setTotalReferralIncome] = useState(0);
    const [invested, setTotalInvested]= useState<string|number>(0);


    useEffect(() => {
        // Fetch number of users
        const fetchUsersAndBalances = async () => {
            
            try {
      let totalInvestedAmount = 0
    const userResponse = await fetch('https://api.growwincapital.com/api/user/getUsers/all');
    const userData = await userResponse.json();

    if (userData.status === "success" && userData.user) {
        const usersArray: User[] = Object.values(userData.user) as User[];
        setUsers(usersArray); // Assuming you want to keep the original order
        setUserCount(usersArray.length)

      const totalReferralIncomePerUser:any  = {};

      await Promise.all(usersArray.map(async (user) => {
        const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': '*',
		};
          const refereeResponse = await fetch(`https://api.growwincapital.com/api/referalIncome/getReferalIncome/${user.email}`);
        // const investedResponse = await fetch(`https://api.growwincapital.com/api/invest/getInvest/${user.email}`,{headers});
           try {
        const response = await fetch(`https://api.growwincapital.com/api/invest/getInvest/${user.email}`);

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`); // Throw an error if the response is not OK.
        }

        const data = await response.json(); 
        let InvestAmount = parseFloat(data.Invest.totalAmount)
        console.log(InvestAmount)
        if (!isNaN(InvestAmount)) { // Check if the parsed value is a number
        totalInvestedAmount += InvestAmount;
      }
        // console.log(totalInvestedAmount);
        // If you have state to update, you might do it here. For example:

    } catch (error) {
        console.error('Failed to fetch investment data:', error);
        // Handle the error as needed in your application. For example:
        // setError(error.message);
    }
        const refereeData = await refereeResponse.json();
        if (refereeData.status === "success" && refereeData.referalIncome) {
          const referralIncomes = Object.values(refereeData.referalIncome);

          const totalIncomeForUser = referralIncomes.reduce((acc:any, incomeRecord:any) => {
            return acc + parseFloat(incomeRecord.referalIncome || '0');
          }, 0);

          totalReferralIncomePerUser[user.email] = totalIncomeForUser;
        }
      }));
        setTotalInvested(totalInvestedAmount? totalInvestedAmount : 'loading...');

      // If you need to set the total referral income for all users combined:
      const totalReferralIncome:any = Object.values(totalReferralIncomePerUser).reduce((acc:any, income) => acc + income, 0);
      setTotalReferralIncome(totalReferralIncome);

    } else {
      console.error('Unexpected response structure:', userData);
      setUsers([]);
    }
  } catch (error) {
    console.error('Failed to fetch users or referral incomes:', error);
    setUsers([]);
  }
};


        // Ensure you have the correct endpoints for these fetches
        // Fetch total deposits
        fetch('https://api.growwincapital.com/api/deposit/getDeposit') // Updated URL
            .then(response => response.json())
            .then(data => {
                let a = 0;
                let num = Object.values(data.Deposit).length
                if(num > 0)
                {
                    Object.values(data.Deposit).forEach((e: any) => {
                           if(e.status === 'success')
                           {
                            a += parseFloat(e.amount);
                           }       	  
                    });
                    setTotalDeposits(a);
                }else{
                    setTotalDeposits(0);
                }
            });

        // // Fetch total withdrawals
        fetch('https://api.growwincapital.com/api/withdraw/getWithdraw') // Updated URL
            .then(response => response.json())
            .then(data => {
                let a = 0;
                let num = Object.values(data.Withdraw).length
                if(num > 0)
                {
                    Object.values(data.Withdraw).forEach((e: any) => {
                           if(e.status === 'Success')
                           {
                            a += parseFloat(e.amount);
                           }       	  
                    });
                    setTotalWithdrawals(a);
                }else{
                    setTotalWithdrawals(0);
                }
            });

        // // Fetch total transfers
        fetch('https://api.growwincapital.com/api/transfer/getTransferall') // Updated URL
            .then(response => response.json())
            .then(data => {
                let a = 0;
                let num = Object.values(data.Transfer).length
                if(num > 0)
                {
                    Object.values(data.Transfer).forEach((e: any) => {
                        
                        a += parseFloat(e.amount);
                               	  
                    });
                    setTotalTransfers(a);
                }else{
                    setTotalTransfers(0);
                }
            });
            fetchUsersAndBalances()
    }, []);

    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4'>
                <div className='bg-white shadow rounded-lg p-4'>
                    <span className='text-gray-600'>Total Users</span>
                    <div className='text-xl font-bold'>{userCount}</div>
                </div>
                <div className='bg-white shadow rounded-lg p-4'>
                    <span className='text-gray-600'>Total Deposits</span>
                    <div className='text-xl font-bold'>${totalDeposits}</div>
                </div>
                <div className='bg-white shadow rounded-lg p-4'>
                    <span className='text-gray-600'>Total Withdrawals</span>
                    <div className='text-xl font-bold'>${totalWithdrawals}</div>
                </div>
                <div className='bg-white shadow rounded-lg p-4'>
                    <span className='text-gray-600'>Total Transfers</span>
                    <div className='text-xl font-bold'>${totalTransfers}</div>
                </div>
                <div className='bg-white shadow rounded-lg p-4'>
                    <span className='text-gray-600'>Total Referral Income</span>
                    <div className='text-xl font-bold'>${totalReferralIncome.toFixed(2)}</div>
                </div>

                <div className='bg-white shadow rounded-lg p-4'>
                    <span className='text-gray-600'>Total Invested</span>
                    <div className='text-xl font-bold'>${invested}</div>
                </div>

            </div>
        </div>
    );
}

export default AdminDashboard;
