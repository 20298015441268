import axios from 'axios';
import React, { useState, useEffect } from 'react';

type User = {
  _id: string;
  name: string;
  email: string;
  number: string;
  refree: string;
  createdAt: string;
  active: boolean;
};

const Wallet: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [balances, setBalances] = useState<Record<string, string>>({});
  const [debitValues, setDebitValues] = useState<Record<string, string>>({});
  const [creditValues, setCreditValues] = useState<Record<string, string>>({});
    const [searchTerm, setSearchTerm] = useState(''); // State for the search term


  useEffect(() => {
    const fetchUsersAndBalances = async () => {
      try {
        const userResponse = await fetch('https://api.growwincapital.com/api/user/getUsers/all');
        const userData = await userResponse.json();

        if (userData.status === "success" && userData.user) {
          // Ensure userData.user is treated as an array
          const usersArray: User[] = Array.isArray(userData.user) ? userData.user : Object.values(userData.user);
          setUsers(usersArray.reverse());

          const balancePromises = usersArray.map(async (user) => {
            try {
              const balanceResponse = await fetch(`https://api.growwincapital.com/api/wallet/walletbalance/${user.email}`);
              const balanceData = await balanceResponse.json();
              return { email: user.email, balance: balanceData.wallet.balance };
            } catch (error) {
              console.error(`Failed to fetch balance for ${user.email}:`, error);
              return { email: user.email, balance: 'Error' }; 
            }
          });

          const balancesArray = await Promise.all(balancePromises);
          const newBalances = balancesArray.reduce((acc, { email, balance }) => {
            acc[email] = balance;
            return acc;
          }, {} as Record<string, string>);

          setBalances(newBalances);
        } else {
          console.error('Unexpected response structure:', userData);
        }
      } catch (error) {
        console.error('Failed to fetch users or balances:', error);
      }
    };

    fetchUsersAndBalances();
  }, []);
  const handleDebit = async (email: string, amount:  any) => {
    const headers = {
      'Content-Type': 'application/json',
    };
  
    const currentBalance = parseFloat(balances[email] || '0');
    const debitAmount = parseFloat(amount);
    let newBalanceNum = Math.max(0, currentBalance - debitAmount); 
    const newBalanceStr = newBalanceNum.toFixed(2);
    
    try {
      const response = await fetch('https://api.growwincapital.com/api/wallet/updateWallet', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          email: email,
          amount: newBalanceStr.toString(), 
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
  
      // Update the balances state to reflect the debit operation
      setBalances((prevBalances) => ({
        ...prevBalances,
        [email]: newBalanceStr.toString(),
      }));
  
      // Reset the debit input field for this user
      setDebitValues((prevValues) => ({
        ...prevValues,
        [email]: '',
      }));
    } catch (error) {
      console.error('Error updating wallet:', error);
    }
  };
  
  const handleCredit = async (email: string, amount: any) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    const currentBalance = parseFloat(balances[email] || '0'); 
    const creditAmount = parseFloat(amount); 
    

    const newBalance = currentBalance + creditAmount;
    
 
    const formattedNewBalance = newBalance.toFixed(2);
    
    try {
      const response = await fetch('https://api.growwincapital.com/api/wallet/updateWallet', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          email: email,
          amount: formattedNewBalance,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
  
      // Update the balances state to reflect the credit operation
      setBalances((prevBalances) => ({
        ...prevBalances,
        [email]: newBalance.toString(),
      }));
  
      // Reset the credit input field for this user
      setCreditValues((prevValues) => ({
        ...prevValues,
        [email]: '',
      }));
    } catch (error) {
      console.error('Error updating wallet:', error);
    }
  };
  

  const handleInputChange = (email: any, value: any, type: string) => {
    if (type === 'debit') {
      setDebitValues(prevValues => ({
        ...prevValues,
        [email]: value,
      }));
    } else if (type === 'credit') {
      setCreditValues(prevValues => ({
        ...prevValues,
        [email]: value,
      }));
    }
  };

    // Function to handle search term changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter users based on the search term
  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-2">

       <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <h1 className="text-2xl font-semibold mb-4">{`Total Users: ${users.length}`}</h1>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">S.No</th>
              <th scope="col" className="py-3 px-6">Name</th>
              <th scope="col" className="py-3 px-6">Email</th>
              <th scope="col" className="py-3 px-6">Balance</th>
              <th scope="col" className="py-3 px-6">Update Balance</th>
              
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user._id} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{index + 1}</td>
                <td className="py-4 px-6">{user.name}</td>
                <td className="py-4 px-6">{user.email}</td>
                <td className="py-4 px-6">{Number(balances[user.email]).toFixed(2) || 'Loading...'}</td>
                <td className="py-4 px-6 flex items-center">
  <input
    type="text"
    value={debitValues[user.email] || ''}
    onChange={(e) => handleInputChange(user.email, e.target.value, 'debit')}
    style={{
      marginRight: '8px', // Add space between input and button
      border: '1px solid #ccc', // Add border for visibility
    }}
    className="text-field-class"
  />
  <button
    onClick={() => handleDebit(user.email, debitValues[user.email])}
    style={{ 
      backgroundColor: 'red', 
      color: 'white',
      padding: '8px 16px', // Add padding to the text inside the button
      borderRadius: '8px', // Round the corners of the button
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
      border: 'none', // Remove the default button border
    }}
    className="update-button-class"
  >
    Debit
  </button>
</td>
<td className="py-2 px-6 flex items-center">
  <input
    type="text"
    value={creditValues[user.email] || ''}
    onChange={(e) => handleInputChange(user.email, e.target.value, 'credit')}
    style={{
      marginRight: '8px', // Add space between input and button
      border: '1px solid #ccc', // Add border for visibility
    }}
    className="text-field-class"
  />
  <button
    onClick={() => handleCredit(user.email, creditValues[user.email])}
    style={{ 
      backgroundColor: 'green', 
      color: 'white',
      padding: '8px 16px', // Add padding to the text inside the button
      borderRadius: '8px', // Round the corners of the button
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
      border: 'none', // Remove the default button border
    }}
    className="update-button-class"
  >
    Credit
  </button>
</td>


              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Wallet;
