import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const Layout = () => {
    const location = useLocation();
    const getTopBarTitle = (pathname: string) => {
        switch (pathname) {
            case '/':
                return 'Dashboard';
            case '/withdraw':
                return 'Withdraw';
            case '/users':
                return 'Users';
            case '/teamDetails':
                return 'Team Details';
            case '/transferDetails':
                return 'Transfer Details';
            case '/banner':
                return 'Change App Banners';
            case '/deposit':
                    return 'Deposits';
            case '/referal':
                    return 'Referal Income';
            case '/teamincome':
                    return 'Team Income';
            case '/income':
                    return 'Income';
            case '/invest':
                    return 'invest';
            case '/investHistory':
                    return 'Invest History';
            case '/wallet':
                    return 'User Wallet';
            default:
                return 'App';
        }
    };

   return (
        <div className='flex h-screen bg-gray-100 overflow-hidden'>
            <Sidebar />
            <div className='flex-1 overflow-auto relative'>
                <div className="bg-red-500 text-white text-2xl font-semibold p-4 sticky top-0 z-10">
                    {getTopBarTitle(location.pathname)}
                </div>
                <div className='p-10 pt-16'>
                    <Outlet /> {/* This will render the component based on the route */}
                </div>
            </div>
        </div>
    );
    // (
    //     <div className='flex h-screen bg-gray-100 overflow-hidden'>
    //         <Sidebar />
    //         <div className='flex-1 overflow-auto relative'>
    //             <div className="bg-red-500 text-white text-2xl font-semibold p-4 sticky top-0 z-10">
    //                 {getTopBarTitle(location.pathname)} {/* Dynamic top bar title */}
    //             </div>
    //             <div className='p-10 pt-16'>
    //                 <Outlet />
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default Layout;
