import React, { useState, useEffect } from 'react';
import axios from 'axios';

type User = {
  _id: string;
  name: string;
  email: string;
  number: string;
  refree: string;
  createdAt: string;
  active: boolean;
  investmentAllowed:boolean;
};

type Balance = {
  balance: number;
};

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [referredBy, setReferredBy] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [masterPassword, setMasterPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchUsersAndBalances = async () => {
      try {
        const userResponse = await fetch('https://api.growwincapital.com/api/user/getUsers/all');
        const userData = await userResponse.json();

        if (userData.status === "success" && userData.user) {
          const usersArray: User[] = Object.values(userData.user) as User[];
          const reversedUsersArray = usersArray.reverse();
          setUsers(reversedUsersArray);
          const referredByMap: Record<string, string> = {};

          await Promise.all(usersArray.map(async (user) => {
            const refereeResponse = await fetch(`https://api.growwincapital.com/api/referal/getReferal/${user.refree}`);
            const refereeData = await refereeResponse.json();
            if (refereeData && refereeData.email && refereeData.email.email) {
              referredByMap[user.email] = refereeData.email.email;
            } else {
              referredByMap[user.email] = 'Not Referred';
            }
          }));
          setReferredBy(referredByMap);
        } else {
          console.error('Unexpected response structure:', userData);
          setUsers([]);
        }
      } catch (error) {
        console.error('Failed to fetch users or balances:', error);
        setUsers([]);
      }
    };

    fetchUsersAndBalances();
  }, []);

  async function toggleActiveStatus(email: string) {
    try {
      const user = users.find(user => user.email === email);
      if (!user) {
        console.error('User not found');
        return;
      }
      const isActive = !user.active;
      const response = await fetch(`https://api.growwincapital.com/api/user/toggleActiveStatusByEmail`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email: email,
          active: isActive }), 
      });

      if (response.ok) {
        setUsers(prevUsers =>
          prevUsers.map(user =>
            user.email === email ? { ...user, active: isActive } : user
          )
        );
      } else {
        console.error('Failed to toggle active status:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to toggle active status:', error);
    }
  }
  async function toggleInvestmentStatus(email: string) {
    try {
      const user = users.find(user => user.email === email);
      if (!user) {
        console.error('User not found');
        return;
      }
      const isActive = !user.investmentAllowed;
      const response = await fetch(`https://api.growwincapital.com/api/invest/toggle-investment-allowed/${email}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        }
    });

      if (response.ok) {
        setUsers(prevUsers =>
          prevUsers.map(user =>
            user.email === email ? { ...user, investmentAllowed: isActive } : user
          )
        );
      } else {
        console.error('Failed to toggle active status:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to toggle active status:', error);
    }
  }

  const handleOpenAccount = async () => {
    if (!selectedUserId) return;

    try {
      const response = await axios.post(`https://api.growwincapital.com/api/admin/login-as-user`, { userId: selectedUserId });
      const { token, redirectUrl } = response.data;

      localStorage.setItem('token', token);
      window.open(redirectUrl, '_blank');
      setShowModal(false);
      setMasterPassword('');
      setErrorMessage('');
    } catch (error) {
      console.error('Failed to log in as user:', error);
    }
  };

  const validatePassword = async () => {
    try {
      const response = await axios.post(`https://api.growwincapital.com/validate-master-password`, { password: masterPassword });
      if (response.data.valid) {
        handleOpenAccount();
      } else {
        setErrorMessage('Invalid password');
      }
    } catch (error) {
      console.error('Failed to validate password:', error);
      setErrorMessage('Error correct password');
    }
  };

  const filteredUsers = searchTerm
    ? users.filter(user => user.email.toLowerCase().includes(searchTerm.toLowerCase()))
    : users;

  const closeModal = () => {
    setShowModal(false);
    setMasterPassword('');
    setErrorMessage('');
  };

  return (
    <div className="p-2">
      <div className="mb-4">
        <label htmlFor="search" className="sr-only">Search by email</label>
        <input
          type="text"
          id="search"
          className="w-full p-2 rounded border"
          placeholder="Search by email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <h1 className="text-2xl font-semibold mb-4">{`Total Users: ${Object.keys(users).length}`}</h1>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg overflow-scroll">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">S.No</th>
              <th scope="col" className="py-3 px-6">Name</th>
              <th scope="col" className="py-3 px-6">Email</th>
              <th scope="col" className="py-3 px-6">Phone Number</th>
              <th scope="col" className="py-3 px-6">Referred By</th>
              <th scope="col" className="py-3 px-6">Created At</th>
              <th scope="col" className="py-3 px-6">Status</th>
              <th scope="col" className="py-3 px-6">Stop Investment</th>
              <th scope="col" className="py-3 px-6">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user._id} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{index + 1}</td>
                <td className="py-4 px-6">{user.name}</td>
                <td className="py-4 px-6">{user.email}</td>
                <td className="py-4 px-6">{user.number}</td>
                <td className="py-4 px-6">{referredBy[user.email] || 'Loading...'}</td>
                <td className="py-4 px-6">{new Date(user.createdAt).toLocaleDateString()}</td>
                <td className="py-4 px-6">
                  <button
                    onClick={() => toggleActiveStatus(user.email)}
                    className={`py-2 px-4 rounded ${user.active ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
                  >
                    {user.active ? 'Active' : 'Inactive'}
                  </button>
                </td>
                <td className="py-4 px-6">
                  <button
                    onClick={() => toggleInvestmentStatus(user.email)}
                    className={`py-2 px-4 rounded ${user.investmentAllowed ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
                  >
                    {user.investmentAllowed ? 'Active' : 'Inactive'}
                  </button>
                </td>
                <td className="py-4 px-6">
                  <button
                    onClick={() => {
                      setSelectedUserId(user._id);
                      setShowModal(true);
                    }}
                    className="py-2 px-4 bg-blue-500 text-white rounded"
                  >
                    Open Account
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-lg font-medium mb-4">Enter Master Password</h2>
            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
            <input
              type="password"
              className="w-full p-2 mb-4 border rounded"
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              placeholder="Master Password"
            />
            <button
              onClick={validatePassword}
              className="w-full py-2 px-4 bg-blue-500 text-white rounded"
            >
              Submit
            </button>
            <button
              onClick={closeModal}
              className="w-full py-2 px-4 mt-2 bg-gray-500 text-white rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
