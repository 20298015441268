import React, { useState, useEffect } from 'react';

interface SubTeamMember {
  _id: string;
  createdAt: string;
  level: number;
  member: string;
  name: string;
  number: string;
  owner: string;
  updatedAt: string;
  __v: number;
}

const TeamDetail: React.FC = () => {
  
  const [teamDetails, setteamDetails] = useState<SubTeamMember[]>([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  useEffect(()=>{
       fetchteamDetails()
  },[])

  
const fetchteamDetails = async () => {
  try {
    // Fetching team details initiated by the user
    const response = await fetch(`https://api.growwincapital.com/api/subTeam/getSubTeamall`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    
    if (data.status === "success" && data.subTeam) {
      // Converting the team object into an array using Object.values()
      const teamDetailsArray:any = Object.values(data.subTeam);
      // Setting the state with the team details array
      setteamDetails(teamDetailsArray);
    } else {
      console.error('Unexpected response structure:', data);
      setteamDetails([]);
    }

  } catch (error) {
    console.error(`Error fetching team details:`, error);
    // Clear the state in case of an error
    setteamDetails([]);
  }
};

const filteredDepositData = teamDetails.filter((item:any) =>
    item.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.member.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">team Details</h1>
        <input
        type="text"
        placeholder="Search by owner or member email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Owner</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Member</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">level</th>
            </tr>
          </thead>
          <tbody>
            {[...filteredDepositData].reverse().map((request: any, index: any) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{request.owner} </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.member}  </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
                <td className="py-3 px-8 border-b border-gray-200">
  {request.level}
</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamDetail;
