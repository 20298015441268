import { useState, useEffect } from "react";
import axios from "axios";

const Deposits = () => {
  const [depositData, setDepositData] = useState<any>([]);
  const [status, setStatus] = useState(false);

  //  const [depositData] = useState([
  //   {
  //     _id: "1",
  //     email: "adilrana2014@gmail.com",
  //     amount: "100",
  //     createdAt: "2023-03-16T12:00:00Z",
  //     status: "Success",
  //   },
  //   {
  //     _id: "2",
  //     email: "jane.doe@example.com",
  //     amount: "250",
  //     createdAt: "2023-03-15T15:30:00Z",
  //     status: "Pending",
  //   },
  //   {
  //     _id: "3",
  //     email: "john.doe@example.com",
  //     amount: "500",
  //     createdAt: "2023-03-14T09:45:00Z",
  //     status: "Failed",
  //   },
  //   // Add more dummy data as needed
  // ]);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    axios
      .get(`https://api.growwincapital.com/api/deposit/getDeposit/all`, { headers })
      .then((response) => {
        console.log(response.data.Deposit);
        const deposit: any = Object.values(response.data.Deposit);
        setDepositData(deposit);
      })
      .catch((error) => {
        console.log("no error " + error);
      });
  }, [status]);


  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Deposit Requests</h1>
      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Time</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody>
            {[...depositData].reverse().map((request: any, index: any) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{request.email} </td>
                <td className="py-3 px-8 border-b border-gray-200">${request.amount}  </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.createdAt} </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.status}</td>
             
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Deposits;
