import { useState, useEffect } from "react";
import axios from "axios";

const Referal = () => {
  const [depositData, setDepositData] = useState<any>([]);
  const [status, setStatus] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    axios
      .get(`https://api.growwincapital.com/api/referalIncome/getReferalIncomeAll`, { headers })
      .then((response) => {
        console.log(response.data.Referal);
        const deposit: any = Object.values(response.data.Referal);
        setDepositData(deposit);
      })
      .catch((error) => {
        console.log("no error " + error);
      });
  }, [status]);

    // Event handler for search input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filtered data based on the search term
  const filteredDepositData = depositData.filter((item:any) =>
    item.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.member.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Referal Income</h1>
        <input
        type="text"
        placeholder="Search by owner or member email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">To</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">From</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Level</th>
            </tr>
          </thead>
          <tbody>
            {[...filteredDepositData].reverse().map((request: any, index: any) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{request.owner} </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.member}  </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
                <td className="py-3 px-8 border-b border-gray-200">
  {Number(request.referalIncome).toFixed(2)}
</td>

                <td className="py-3 px-8 border-b border-gray-200">{request.level}</td>
             
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Referal;
