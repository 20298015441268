import { useState, useEffect } from "react";
import axios from "axios";

const Income = () => {
  const [depositData, setDepositData] = useState<any>([]);
  const [status, setStatus] = useState(false);
   const [filteredData, setFilteredData] = useState<any[]>([]); // State for filtered deposit data based on search
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [editIndex, setEditIndex] = useState<number | null>(null); // Track which row is being edited
  const [editValue, setEditValue] = useState(''); 

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    axios
      .get(`https://api.growwincapital.com/api/income/getIncomeAll`, { headers })
      .then((response) => {
        console.log(response.data.Referal);
        const deposit: any = Object.values(response.data.Income);
        setDepositData(deposit);
        setFilteredData(deposit); // Initially, filtered data is the same as all data

      })
      .catch((error) => {
        console.log("no error " + error);
      });
  }, [status]);

  useEffect(() => {
    // Filter depositData based on the searchTerm whenever the searchTerm or depositData changes
    const filtered = depositData.filter((item:any) =>
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm, depositData]);
  const handleUpdateIncome = (index: number) => {
    // Calculate the original index in the non-reversed array for filteredData
    const originalIndexFiltered = filteredData.length - 1 - index;
    const itemFiltered = filteredData[originalIndexFiltered];
  
    // Find the same item in depositData to update
    const originalIndexDeposit = depositData.findIndex((depositItem: { email: any; createdAt: any; }) => depositItem.email === itemFiltered.email && depositItem.createdAt === itemFiltered.createdAt);
    const itemDeposit = depositData[originalIndexDeposit];
  
    axios.post('https://api.growwincapital.com/api/income/updateIncome', { email: itemFiltered.email, income: editValue, date: itemFiltered.createdAt })
      .then(response => {
        // Update filteredData with the new income
        const newDataFiltered = [...filteredData];
        newDataFiltered[originalIndexFiltered].income = editValue; // Update the income in the filtered data
        setFilteredData(newDataFiltered);
  
        // Update depositData with the new income if the item exists in it
        if (itemDeposit && originalIndexDeposit !== -1) {
          const newDataDeposit = [...depositData];
          newDataDeposit[originalIndexDeposit].income = editValue; // Update the income in the original data
          setDepositData(newDataDeposit);
        }
  
        setEditIndex(null); // Reset edit mode
      })
      .catch(error => {
        console.error('Error updating income:', error);
      });
  };
  
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Self Income</h1>
        <input
        type="text"
        placeholder="Search by user email..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border rounded"
      />
      
      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Update Income</th>
            </tr>
          </thead>
          <tbody>
            {[...filteredData].reverse().map((request: any, index: any) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{request.email} </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
                <td className="py-3 px-8 border-b border-gray-200">
  {Number(request.income).toFixed(2)}
</td>
<td className="py-3 px-8 border-b border-gray-200">
              {editIndex === index ? (
                <>
                  <input
                    type="text"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    className="mb-4 p-2 border rounded"
                  />
                  <button
                    onClick={() => handleUpdateIncome(index)}
                    className="p-2 border rounded bg-blue-500 text-white"
                  >
                    Update
                  </button>
                </>
              ) : (
                <button
                  onClick={() => { setEditIndex(index); setEditValue(request.income.toString()); }}
                  className="p-2 border rounded bg-green-500 text-white"
                >
                  Edit
                </button>
              )}
            </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Income;
