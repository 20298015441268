import { useState, useEffect } from "react";
import axios from "axios";

const Invest = () => {
  const [depositData, setDepositData] = useState<any>([]);
  const [status, setStatus] = useState(false);
  const [debitValues, setDebitValues] = useState<Record<string, string>>({});
  const [creditValues, setCreditValues] = useState<Record<string, string>>({});
    const [searchTerm, setSearchTerm] = useState(''); // State for the search term


  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    axios
      .get(`https://api.growwincapital.com/api/invest/getInvestall`, { headers })
      .then((response) => {
        console.log(response.data.Referal);
        const deposit: any = Object.values(response.data.Invest);
        setDepositData(deposit);
      })
      .catch((error) => {
        console.log("no error " + error);
      });
  }, [status]);

  const handleDebit = async (email: string, amount: any,incomdate:any) => {
    const headers = {
      'Content-Type': 'application/json',
    };
  
    // Find the index of the user in the depositData array
    const index = depositData.findIndex((user: any) => user.email === email);
    if (index !== -1) {
      const currentInvestment = parseFloat(depositData[index].totalAmount);
      const debitAmount = parseFloat(amount);
      let newInvestment = Math.max(0, currentInvestment - debitAmount);
      if (newInvestment < 20) {
        newInvestment = 20;
      }  
      const formattedNewInvestment = newInvestment.toFixed(2);
    let  subscription = parseFloat(formattedNewInvestment) > 2001 ? 'Classic' : 'Basic';
      try {
        const response = await fetch(`https://api.growwincapital.com/api/invest/updateInvest`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            email: email,
            Subscription: subscription,
            incomeDate:incomdate,
            totalAmount:  formattedNewInvestment,
            amount:debitAmount.toFixed(2),
            type:"debit"
          }),
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
    
        // Update the depositData state to reflect the debit operation
        const updatedDepositData = [...depositData];
        updatedDepositData[index].totalAmount = formattedNewInvestment;
        updatedDepositData[index].subscription = subscription; 
        setDepositData(updatedDepositData);
    
        // Reset the debit input field for this user
        setDebitValues((prevValues) => ({
          ...prevValues,
          [email]: '',
        }));
      } catch (error) {
        console.error('Error updating wallet:', error);
      }
    }
  };
  

  
  const handleCredit = async (email: string, amount: any,incomdate:any) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    // Find the index of the user in the depositData array
    const index = depositData.findIndex((user: any) => user.email === email);
    if (index !== -1) {
      const currentInvestment = parseFloat(depositData[index].totalAmount);
      const creditAmount = parseFloat(amount); 
      const newInvestment = currentInvestment + creditAmount;
      const formattedNewInvestment = newInvestment.toFixed(2);
      let  subscription = parseFloat(formattedNewInvestment) > 2001 ? 'Classic' : 'Basic';
      try {
     
        const response = await fetch('https://api.growwincapital.com/api/invest/updateInvest', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            email: email,
            Subscription: subscription,
            incomeDate:incomdate,
            totalAmount:  formattedNewInvestment,
            amount:creditAmount.toFixed(2),
            type:"credit"
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        // Update the depositData state to reflect the credit operation
        const updatedDepositData = [...depositData];
        updatedDepositData[index].totalAmount = formattedNewInvestment;
        updatedDepositData[index].subscription = subscription; 
        setDepositData(updatedDepositData);
    
        // Reset the credit input field for this user
        setCreditValues((prevValues) => ({
          ...prevValues,
          [email]: '',
        }));
      } catch (error) {
        console.error('Error updating wallet:', error);
      }
    }
  };

 // Handle the closure of an investment
 const handleCloseInvestment = async (email: string) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetch(`https://api.growwincapital.com/api/invest/close-investment/${email}`, {
      method: 'PUT',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);

    // Update the state to show that the investment is closed
    const updatedDepositData = depositData.map((user: any) =>
      user.email === email ? { ...user, isClosed: true } : user
    );
    setDepositData(updatedDepositData);
  } catch (error) {
    console.error('Error closing investment:', error);
  }
};
  const handleInputChange = (email: any, value: any, type: string) => {
    if (type === 'debit') {
      setDebitValues(prevValues => ({
        ...prevValues,
        [email]: value,
      }));
    } else if (type === 'credit') {
      setCreditValues(prevValues => ({
        ...prevValues,
        [email]: value,
      }));
    }
  };  // Function to handle search term changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter depositData based on the search term
  const filteredDepositData = depositData.filter((item:any) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Invest</h1>
      <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />

      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Investment Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Invest Amount</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Subscription</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Update Investment</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Close Investment</th>
            </tr>
          </thead>
          <tbody>
            {[...filteredDepositData].reverse().map((request: any, index: any) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{request.email} </td>
                <td className="py-3 px-8 border-b border-gray-200">
  {new Date(request.createdAt).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).replace(/ /g, '/')}
</td>
<td className="py-3 px-8 border-b border-gray-200">
  {new Date(request.incomeDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).replace(/ /g, '/')}
</td>
                <td className="py-3 px-8 border-b border-gray-200">
  {request.isClosed?0:Number(request.totalAmount).toFixed(2)}
</td>
<td className="py-3 px-8 border-b border-gray-200">{request.subscription}  </td>
<td>
                  {/* Conditionally render Debit/Credit if investment is not closed */}
                  {!request.isClosed ? (
                    <>
                     <td className="py-4 px-6 flex items-center">
  <input
    type="text"
    value={debitValues[request.email] || ''}
    onChange={(e) => handleInputChange(request.email, e.target.value, 'debit')}
    style={{
      marginRight: '8px', // Add space between input and button
      border: '1px solid #ccc', // Add border for visibility
    }}
    className="text-field-class"
  />
  <button
    onClick={() => handleDebit(request.email, debitValues[request.email],new Date(request.incomeDate))}
    style={{ 
      backgroundColor: 'red', 
      color: 'white',
      padding: '8px 16px', // Add padding to the text inside the button
      borderRadius: '8px', // Round the corners of the button
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
      border: 'none', // Remove the default button border
    }}
    className="update-button-class"
  >
    Debit
  </button>
</td>
<td className="py-4 px-6 flex items-center">
  <input
    type="text"
    value={creditValues[request.email] || ''}
    onChange={(e) => handleInputChange(request.email, e.target.value, 'credit')}
    style={{
      marginRight: '8px', // Add space between input and button
      border: '1px solid #ccc', // Add border for visibility
    }}
    className="text-field-class"
  />
  <button
    onClick={() => handleCredit(request.email, creditValues[request.email],new Date(request.incomeDate))}
    style={{ 
      backgroundColor: 'green', 
      color: 'white',
      padding: '8px 16px', // Add padding to the text inside the button
      borderRadius: '8px', // Round the corners of the button
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
      border: 'none', // Remove the default button border
    }}
    className="update-button-class"
  >
    Credit
  </button>
</td>
                    </>
                  ) : (
                    <span>Closed</span> // Display "Closed" if the investment is closed
                  )}
                </td>
<td>
                  {/* Close Investment Button */}
                  {request.isClosed ? (
                    <span>Closed</span>
                  ) : (
                    <button
                      onClick={() => handleCloseInvestment(request.email)}
                      style={{ 
                         backgroundColor: 'red', 
                        color: 'white',
                        padding: '4px 8px', // Add padding to the text inside the button
                        borderRadius: '8px', // Round the corners of the button
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
                        border: 'none', // Remove the default button border
                         }}
                    >
                      Close Investment
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invest;
