import { useState, useEffect } from "react";
import axios from "axios";
interface SubTeamMember {
    level: number; // Adjust the type according to your actual data structure
    // Include other properties as needed
  }
  
  interface SubTeamResponse {
    SubTeam: Record<string, SubTeamMember>;
  }
  interface DepositItem {
    _id: string; // Assuming ObjectId can be treated as string in your context
  emailOwner: string;
  emailMember: string;
  Income: string; // 'Income' is a string here, but you might want to convert it to a number for calculations
  createdAt: string; // ISODate can be represented as string in TypeScript and converted to Date object when needed
  updatedAt: string;
  __v: number;
  
  }
  
  interface DepositResponse {
    teamIncome: Record<string, DepositItem>;
  }

const TeamIncome = () => {
  const [depositData, setDepositData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term


//   useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };
//     axios
//       .get(`https://api.growwincapital.com/api/teamincome/getIncomeTeamAll`, { headers })
//       .then((response) => {
//         console.log(response.data.Referal);
//         const deposit: any = Object.values(response.data.teamIncome);
//         setDepositData(deposit);
//       })
//       .catch((error) => {
//         console.log("no error " + error);
//       });
//   }, [status]);

useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };
    setIsLoading(true);
    axios
      .get<DepositResponse>(`https://api.growwincapital.com/api/teamincome/getIncomeTeamAll`, { headers })
      .then(async (response) => {
        const deposit = Object.values(response.data.teamIncome);

        // Fetch levels for each deposit item
        const levelPromises = deposit.map((item) =>
          axios.get<SubTeamResponse>(`https://api.growwincapital.com/api/subTeam/getsubTeam/${item.emailOwner}/${item.emailMember}`, { headers })
            .then(response => Object.values(response.data.SubTeam)[0]?.level || null) // Extract level or null if not found
            .catch(() => null) // Return null in case of an error
        );

        // Wait for all level fetch promises to resolve
        const levels = await Promise.all(levelPromises);

        // Merge the deposit data with the fetched levels
        const depositWithLevels = deposit.map((item, index) => ({
          ...item,
          level: levels[index], // Add level to each deposit item
        }));

        setDepositData(depositWithLevels);
          setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error fetching team income data: " + error);
          setIsLoading(false)
      });
  }, []);

    // Event handler for search input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filtered data based on the search term
  const filteredDepositData = depositData.filter((item: DepositItem) =>
    item.emailOwner.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.emailMember.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Team Income</h1>
        <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <div className="h-[690px] overflow-scroll">
          {isLoading ? (
          <div>Loading...</div> 
        ) : (
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">To</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">From</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Level</th>
            </tr>
          </thead>
          <tbody>
            {[...depositData].reverse().map((request: any, index: any) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{request.emailOwner} </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.emailMember}  </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
                <td className="py-3 px-8 border-b border-gray-200">
                   {Number(request.Income).toFixed(2)}
                </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.level}</td>
              </tr>
            ))}
          </tbody>
        </table>
                )}

      </div>
    </div>
  );
};

export default TeamIncome;
