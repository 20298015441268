import React, { useEffect, useState } from 'react';

interface TransferDetail {
  _id: string;
  owner: string;
  member: string;
  amount: string;
  createdAt: string;
  updatedAt: string;
}

const TransferDetails: React.FC = () => {
  
  const [transferDetails, setTransferDetails] = useState<TransferDetail[]>([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  useEffect(()=>{
       fetchTransferDetails()
  },[])

  
const fetchTransferDetails = async () => {
  try {
    // Fetching transfer details initiated by the user
    const response = await fetch(`https://api.growwincapital.com/api/transfer/getTransferall`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    
    if (data.status === "success" && data.Transfer) {
      // Converting the Transfer object into an array using Object.values()
      const transferDetailsArray:any = Object.values(data.Transfer);
      // Setting the state with the transfer details array
      setTransferDetails(transferDetailsArray);
    } else {
      console.error('Unexpected response structure:', data);
      setTransferDetails([]);
    }

  } catch (error) {
    console.error(`Error fetching transfer details:`, error);
    // Clear the state in case of an error
    setTransferDetails([]);
  }
};

const filteredDepositData = transferDetails.filter((item:any) =>
    item.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.member.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Transfer Details</h1>
        <input
        type="text"
        placeholder="Search by owner or member email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">From</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">To</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
            </tr>
          </thead>
          <tbody>
            {[...filteredDepositData].reverse().map((request: any, index: any) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{request.owner} </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.member}  </td>
                <td className="py-3 px-8 border-b border-gray-200">{request.createdAt}  </td>
                <td className="py-3 px-8 border-b border-gray-200">
  {Number(request.amount).toFixed(2)}
</td>

                
             
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransferDetails;
