
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { Alert, Snackbar, CircularProgress, Grid, AlertColor, Box } from '@mui/material';
import axios from 'axios';

interface ImageData {
  _id: string;
  type: string;
  image: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

interface ImageDropzoneProps {
  image: ImageData; 
  index: number;
  onDrop: (index: number) => (acceptedFiles: File[]) => void; 
  loading: boolean;
}

const ImageDropzone: React.FC<ImageDropzoneProps> = ({ image, index, onDrop, loading }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop(index),
        accept: {
      'image/jpeg': [],
      'image/png': []
    }
  });

  return (
    <div {...getRootProps()} className="dropzone flex flex-col justify-center items-center border-2 border-dashed border-gray-400 rounded-lg text-center py-16 px-8 cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition duration-300 ease-in-out" style={{ minHeight: '300px' }}>
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" className="text-gray-400" />
      <p className="mt-2 text-base leading-4 text-gray-600">Drag 'n' drop an image here, or click to select an image</p>
      <em className="text-xs text-gray-500">(Only *.jpeg and *.png images will be accepted)</em>
      {image.image && (
        <div className="mt-2 flex justify-center items-center" style={{ height: '150px', width: '80%' }}> {/* Adjusted height and width */}
          <img src={image.image} alt={`Preview ${index}`} className="h-full w-auto object-cover" />
        </div>
      )}
      {loading && <CircularProgress color="error" />}
    </div>
  );
};

const Banner = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState<boolean[]>(Array(5).fill(false));
  const [loadingMain, setLoadingMain] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({ severity: "success", open: false, message: '' });

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoadingMain(true);
        const response = await axios.get('https://api.growwincapital.com/api/banner/getBannerall');
        const bannersArray: ImageData[] = Object.values(response.data.Banner).map((banner: any) => ({
          _id: banner._id,
          type: banner.type,
          image: banner.image,
          createdAt: banner.createdAt,
          updatedAt: banner.updatedAt,
          __v: banner.__v,
        }));
        setImages(bannersArray);
        setLoadingMain(false);
      } catch (error) {
        setLoadingMain(false);
        console.error('Error fetching images:', error);
        setSnackbar({ open: true, message: 'Failed to fetch images.', severity: 'error' });
      }
    };
    fetchImages();
  }, []);

  const onDrop = (index: number) => async (acceptedFiles: any[]) => {
    // The rest of your onDrop logic
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      let newImages = [...images];
      newImages[index].image = reader.result as string;
      setImages(newImages);

      setLoading(prev => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });

      try {
        await axios.post('https://api.growwincapital.com/api/banner/updateBanner', {
          type: newImages[index].type,
          image: newImages[index].image
        });
        setSnackbar({ open: true, message: 'Image updated successfully!', severity: 'success' });
      } catch (error) {
        console.error('Error updating the image:', error);
        setSnackbar({ open: true, message: 'Failed to update image.', severity: 'error' });
      } finally {
        setLoading(prev => {
          const newLoading = [...prev];
          newLoading[index] = false;
          return newLoading;
        });
      }
    };
    reader.readAsDataURL(file);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className="p-4">
      <h1 className='text-2xl font-semibold mb-4'>Change App Banners</h1>
      <Grid container spacing={2}>
  {images.map((image, index) => (
    <Grid item xs={12} sm={6} md={6} key={image._id}> 
      <ImageDropzone image={image} index={index} onDrop={onDrop} loading={loading[index]} />
    </Grid>
  ))}
</Grid>
      {loadingMain && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress color="error" />
        </Box>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Banner;
