import { useState, useEffect } from "react";
import axios from "axios";

const InvestmentHistory = () => {
  const [investmentData, setInvestmentData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    axios
      .get(`https://api.growwincapital.com/api/invest/getInvestallhistory`, { headers })
      .then((response) => {
        console.log(response.data.Referal);
        const investment: any = Object.values(response.data.Invest);
        setInvestmentData(investment);
      })
      .catch((error) => {
        console.error("Error fetching investment data:", error);
      });
  }, []);

  // Function to handle search term changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter investmentData based on the search term
  const filteredInvestmentData = investmentData.filter((item: any) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Investment History</h1>
      <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />

      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Total Investment</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Subscription</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Invested Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action Date</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Type</th>
              <th className="py-3 px-8 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvestmentData.reverse().map((investment: any, index: number) => (
              <tr key={index + 1}>
                <td className="py-3 px-8 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-8 border-b border-gray-200">{investment.email}</td>
                <td className="py-3 px-8 border-b border-gray-200">{investment.action}</td>
                <td className="py-3 px-8 border-b border-gray-200">{Number(investment.totalAmount).toFixed(2)}</td>
                <td className="py-3 px-8 border-b border-gray-200">{investment.subscription}</td>
                <td className="py-3 px-8 border-b border-gray-200">
                  {investment.incomeDate ? new Date(investment.investDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).replace(/ /g, '/')
                  : 'N/A'}
                </td>
                <td className="py-3 px-8 border-b border-gray-200">
                  {investment.actionDate ? new Date(investment.actionDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).replace(/ /g, '/')
                  : 'N/A'}
                </td>
                <td className="py-3 px-8 border-b border-gray-200">{investment.type}</td>
                <td className="py-3 px-8 border-b border-gray-200">{investment.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvestmentHistory;
